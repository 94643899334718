import {axios, API, apiErrorHandler} from '@/api'
import { getCorrectUrlParamsFormat } from '../../utilities/helper';
import enrollments from './enrollments';

export default {
  async getDocuments(params: any) {
    try {
      const url = API.getDocuments() + '?' + getCorrectUrlParamsFormat(params) ;
      const response = await axios.get(url)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  },

  async getDocumentById(id, enrolleeId) {
    try {
      const url = API.getDocuments() + '/' + id + '?enrolleeId=' + enrolleeId;
      const response = await axios.get(url, { headers: enrollments.getAuthHeaders() })
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  },

  async createDocument(document) {
      return axios
        .post(API.createDocument(), document, {
          headers : { enctype : `multipart/form-data` }
        })
        .catch(apiErrorHandler)
  },

  async updateDocument(id, formData) {
    try {
      const response = await axios.put(API.updateDocument(id), formData,
      {headers : { 'Content-Type' : `multipart/form-data` }})
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  },
  
  async archiveDocument(id) {
    
    try {
      const response = await axios.delete(API.archiveDocument(id));
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async parsePdfDocument(payload) {
    try {
      const response = await axios.post(API.parsePdfDocument(), payload);
      return response.data;
    } catch (error) {
      console.error(error)
      return error
    }
  },

  async getDocumentNames() {
    try {
      const response = await axios.get(API.getDocumentNames());
      return response.data;
    } catch (error) {
      console.error(error)
      return error;
    }
  }
}
