.
<template>
  <form id="vendorInfo2" @submit.prevent="save()">
    <div class="px-4 lg:col-span-12 pt-0">
      <div class="col-span-6 sm:col-span-6">
        <label for="primary_street_address" class="block text-gray-700">{{ $t('common.form.primaryAddress') }}</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <input
            ref="searchPrimary"
            v-model="vendorInfo2.primaryAddress"
            required
            oninvalid="this.setCustomValidity('Please enter your address.')"
            oninput="this.setCustomValidity('')"
            type="type"
            class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
            placeholder="Enter address"
            id="primary_street_address"
            @keydown.enter.prevent
          />
        </div>
      </div>
      <div class="col-span-6 sm:col-span-6">
        <label for="alternate_street_address" class="block text-gray-700">{{ $t('common.form.mailingAddress') }}</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <input
            v-model="vendorInfo2.alternateAddress"
            ref="searchSecondary"
            type="type"
            id="alternate_street_address"
            class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
            placeholder="Enter address"
            @keydown.enter.prevent
          />
        </div>
      </div>

      <div class="grid grid-cols-6 gap-4">
        <div class="col-span-6 sm:col-span-3">
          <label for="county" class="block text-gray-700">{{ $t('common.form.county') }}</label>
          <div class="flex items-center mt-1">
            <input
              v-model="vendorInfo2.addressPrimary.county"
              required
              oninvalid="this.setCustomValidity('Please enter County.')"
              oninput="this.setCustomValidity('')"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="County"
              id="county"
            />
          </div>
        </div>

        <div class="col-span-6 sm:col-span-3">
          <label for="primary_contact" class="block text-gray-700">{{ $t('common.form.primaryContact') }}</label>
          <div class="flex items-center mt-1">
            <input
              v-model="vendorInfo2.primaryContactPerson"
              type="text"
              required
              oninvalid="this.setCustomValidity('Please enter primary contact name  ')"
              oninput="this.setCustomValidity('')"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="Enter contact name here"
              id="primary_contact"
            />
          </div>
        </div>

        <div class="col-span-6 sm:col-span-3">
          <label for="primary_contact_title" class="block text-gray-700">{{ $t('common.form.primaryContact.title') }}</label>
          <div class="flex items-center mt-1">
            <input
              required
              oninvalid="this.setCustomValidity('Please enter primary contact title.')"
              oninput="this.setCustomValidity('')"
              v-model="vendorInfo2.primaryContactPersonJobTitle"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="Enter title here"
              id="primary_contact_title"
            />
          </div>
        </div>
      </div>
    </div>
    <ContinueBackButtons @back="back"></ContinueBackButtons>
  </form>
</template>

<script>
import {maskPhoneNumber} from 'Utilities/helper';
import addressUtility from 'Utilities/addressUtility';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';

export default {
  components: {
    ContinueBackButtons,
  },

  props: ['participantData'],
  emits: ['save', 'back'],
  data() {
    return {
      counties: '11',
      todayDate: '',

      vendorInfo2: {
        step: 2,
        addressPrimary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          zipcode: '',
          county: '',
          state: '',
        },
        addressSecondary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          zipcode: '',
          county: '',
          state: '',
        },
        primaryContactPerson: '',
        primaryContactPersonJobTitle: '',
      },
    };
  },
  methods: {
    maskPhoneNumber,

    acceptNumber() {
      this.vendorInfo2.phonePrimary = maskPhoneNumber(this.vendorInfo2.phonePrimary);
    },

    acceptSecondaryNumber() {
      this.vendorInfo2.phoneSecondary = maskPhoneNumber(this.vendorInfo2.phoneSecondary);
    },

    save() {
      this.$emit('save', this.vendorInfo2);
    },

    back() {
      this.$emit('back');
    },
    initPrimaryLocationSearch() {
      for (const ref in this.$refs) {
        if (ref === 'searchPrimary') {
          const rf = 'searchPrimary';
          const autocomplete = new window.google.maps.places.Autocomplete(this.$refs[rf], { componentRestrictions: { country: ['us'] }, fields: ['address_components'], types: ['address'] });
          autocomplete.addListener('place_changed', () => {
            this.vendorInfo2.addressPrimary = addressUtility.parseAddressFromGoogleObject(autocomplete.getPlace(), autocomplete.gm_accessors_?.place?.Zr?.formattedPrediction);
          });
        } else if (ref === 'searchSecondary') {
          const rf = 'searchSecondary';
          const autocomplete2 = new window.google.maps.places.Autocomplete(this.$refs[rf], {
            componentRestrictions: { country: ['us'] },
            fields: ['address_components'],
            types: ['address'],
          });
          autocomplete2.addListener('place_changed', () => {
            this.vendorInfo2.addressSecondary = addressUtility.parseAddressFromGoogleObject(autocomplete2.getPlace(), autocomplete2.gm_accessors_?.place?.jj?.formattedPrediction);
          });
        }
      }
    },
  },

  watch: {
    participantData(newEnrollees) {
      if (newEnrollees) {
        this.vendorInfo2 = {
          step: 2,
          primaryAddress: newEnrollees.primaryAddress,
          alternateAddress: newEnrollees.alternateAddress,
          primaryContactPerson: newEnrollees.primaryContactPerson,
          primaryContactPersonJobTitle: newEnrollees.primaryContactPersonJobTitle,
          addressPrimary: {
            addressLine1: newEnrollees.addressPrimary.addressLine1,
            addressLine2: newEnrollees.addressPrimary.addressLine2,
            city: newEnrollees.addressPrimary.city,
            zipcode: newEnrollees.addressPrimary.zipcode,
            county: newEnrollees.addressPrimary.county,
            state: newEnrollees.addressPrimary.state,
          },
          addressSecondary: {
            addressLine1: newEnrollees.addressSecondary.addressLine1,
            addressLine2: newEnrollees.addressSecondary.addressLine2,
            city: newEnrollees.addressSecondary.city,
            zipcode: newEnrollees.addressSecondary.zipcode,
            county: newEnrollees.addressSecondary.county,
            state: newEnrollees.addressSecondary.state,
          },
        };
        this.vendorInfo2.phonePrimary = maskPhoneNumber(this.vendorInfo2.phonePrimary);
        this.vendorInfo2.phoneSecondary = maskPhoneNumber(this.vendorInfo2.phoneSecondary);
      }
    },
  },
  mounted() {
    window.checkAndAttachMapScript(this.initPrimaryLocationSearch);
  },
};
</script>

<style></style>
