.
<template>
  <div class="w-full min-h-screen bg-white md:overflow-x-visible" :class="showSpinner ? 'opacity-25' : ''">
    <div class="flex flex-1 h-screen">
      <main class="flex-1 h-full min-w-0 px-4 pt-4 overflow-x-hidden overflow-y-auto sm:pt-12 2xl:pt-2 lg:px-16 2xl:px-60 lg:flex"><div class="hidden lg:block w-96"></div>
        <section aria-labelledby="primary-heading" class="flex flex-col flex-1 h-full min-w-0 overflow-x-visible pt-14 lg:pt-0 lg:order-last">
          <TransitionScreen>
            <template v-slot:header>
              <h1 class="px-4 mt-6 text-3xl text-darkBlue lg:mt-16">
                {{ $t('esign4.header', { firstName: participant.firstName, previousPacketCompletedText }) }}
              </h1>
            </template>

            <template v-slot:description>
              <span v-if="packetSectionName !== 'upload'">
                <h3 class="px-4 mt-4 text-base font-normal text-darkBlue md:text-xl">
                  {{ $t('esign.nextSection', {packetSectionName }) }}
                </h3>
                <h3 class="px-4 mt-4 mb-10 text-base font-normal text-darkBlue md:text-xl">
                  {{ $t('esign4.description') }}
                </h3>
              </span>
              <span v-else>
                <div v-if="enrolleeType == 1">
                  <h3 class="px-4 mt-4 mb-10 text-base font-normal text-darkBlue md:text-xl" v-html="$t('upload.enrolleeType1.description')"></h3>
                </div>
                <div v-if="enrolleeType == 2">
                  <h3 class="px-4 mt-4 text-base font-normal text-darkBlue md:text-xl">
                    {{ $t('upload.enrolleeType2.description1') }}
                  </h3>
                  <h3 class="px-4 mt-4 mb-10 text-base font-normal text-darkBlue md:text-xl">
                    {{ $t('upload.enrolleeType2.description2') }}
                  </h3>
                </div>
              </span>
            </template>

            <template v-slot:content>
              <ESignContent class="px-4" />
            </template>

            <template v-slot:callout> </template>

            <template v-slot:footer>
              <ContinueBackButtons :continueContent="'Get Started'" @continue="proceedToNext" @back="returnToPrevious"></ContinueBackButtons>
            </template>
          </TransitionScreen>
        </section>
      </main>
      <AppSideNavMenu :participantDetails="participant" :slide="slideName" :id="enrollmentId"/>
    </div>
  </div>
  <GenesysChat></GenesysChat>

  <!-- Spinner -->
  <div class="fixed flex justify-center my-3 text-gray-500 left-1/2 top-1/2" v-if="showSpinner">
    <svg class="h-8 mr-3 -ml-1 animate-spin w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
</template>

<script>
import TransitionScreen from 'Views/EnrollmentPresentation/StaticScreens/transition.vue';
import ESignContent from 'Views/EnrollmentPresentation/TemplateInstances/eSignContent.vue';
import { getEnrolleeInitials } from 'Utilities/helper';
import enrollmentService from 'Services/backend/enrollments';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';
import routingService from "@/services/routingService";
import GenesysChat from "@/views/Shared/GenesysChat.vue";
import {useEnrollmentStore} from "@/stores/enrollment";
import {mapState, mapActions} from "pinia";

export default {
  name: 'E-Sign4',
  mounted() {
    this.enrollmentId = this.$route.params.enrollmentId;
    this.enrolleeId = this.$route.params.enrolleeId;
    this.enrolleeType = this.$route.params.enrolleeType;
  },

  components: {
    GenesysChat,
    TransitionScreen,
    ESignContent,
    ContinueBackButtons,
  },
  data() {
    return {
      step: 4,
      participant: {
        firstName: '',
        lastName: '',
      },
      slideName: 'State Documents',
      enrollmentId: '',
      showSpinner: true,
      packets: {},
      enrolleeType: '',
      nextFirstDoc: '',
      federalLastDoc: '',
      nextRoute: '',
      goBackRoute: '',
      packetSectionName: '',
      previousPacketCompletedText: '',
    };
  },

  methods: {
    getEnrolleeInitials,
    proceedToNext() {
      if (this.showSpinner) return;
      routingService.executeRoute(this.$router, this.nextRoute, this.enrollmentId, this.enrolleeId, this.enrolleeType, this.nextFirstDoc || 0);
      enrollmentService.updateEnrollmentProgress({ enrolleeId: this.enrolleeId, key: 'federalFormsComplete'})
    },
    returnToPrevious() {
      if (this.showSpinner) return;
      routingService.executeRoute(this.$router, this.goBackRoute, this.enrollmentId, this.enrolleeId, this.enrolleeType, this.federalLastDoc.documentId || 0);
    },
    ...mapActions(useEnrollmentStore, ['loadEnrollees']),
  },
  computed: {
    ...mapState(useEnrollmentStore, ['getEnrollees', 'getFullPacket']),
  },
  watch: {
    getEnrollees(newEnrollees) {
      if (newEnrollees) {
        this.showSpinner = false;
        this.participant = getEnrolleeInitials(newEnrollees, this.enrolleeType);
      }
    },
    getFullPacket(newPacket) {
      this.packets = newPacket;
      const res = routingService.determineWorkflowDocRoute(this.packets, this.step, this.enrolleeType);
      this.nextRoute = res.docRoute;
      this.packetSectionName = res.packetSection;
      this.previousPacketCompletedText = res.prevPacket;
      if (this.packets[res.docName]?.length > 0) {
        this.nextFirstDoc = this.packets[res.docName][0].documentId;
      } else {
        this.nextFirstDoc = '';
      }
      
      if (this.packets?.federalDocuments?.length > 0) {
        this.federalLastDoc = this.packets.federalDocuments[this.packets.federalDocuments.length - 1];
        this.goBackRoute = this.federalLastDoc.document?.docFile?.prompts?.length ? 'FederalFormFields' : 'FederalAcknowledgment';
      } else {
        this.federalLastDoc = ''
        this.goBackRoute = routingService.determineEsignRoute(this.packets, this.step);
      }
    },
  },
  async created() {
    await this.loadEnrollees(this.$route.params.enrolleeId);
  },
};
</script>

<style></style>
