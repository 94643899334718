.
<template>
  <div class="w-full min-h-screen overflow-x-hidden overflow-y-auto bg-white" :class="showSpinner ? 'opacity-25' : ''">
    <div class="flex flex-1 h-screen">
      <main class="flex-1 h-full min-w-0 px-4 pt-4 overflow-x-hidden overflow-y-auto sm:pt-12 2xl:pt-2 lg:px-16 2xl:px-60 lg:flex"><div class="hidden lg:block w-96"></div>
        <section aria-labelledby="primary-heading" class="flex flex-col flex-1 h-full min-w-0 overflow-x-visible pt-14 lg:pt-0 lg:order-last">
          <ParticipantInfo>
            <template v-slot:header>
              <h1 class="px-4 mt-6 text-3xl text-darkBlue lg:mt-16">
                {{ $t('upload.header') }}
              </h1>
            </template>

            <template v-slot:description>
              <h3 class="px-4 mt-4 mb-10 text-base font-normal text-darkBlue md:text-xl">
                {{ $t('upload.description') }}
              </h3>
            </template>

            <template v-slot:content>
              <ImageCaptureContent :enrolleeId='enrolleeId' @save="proceedToNext" @back="returnToPrevious" />
            </template>
          </ParticipantInfo>
        </section>
      </main>
      <AppSideNavMenu :participantDetails="getCurrentEnrollee" :slide="slideName" :id="enrollmentId"/>
    </div>
  </div>
  <GenesysChat></GenesysChat>

  <!-- Spinner -->
  <div class="fixed flex justify-center my-3 text-gray-500 left-1/2 top-1/2" v-if="showSpinner">
    <svg class="h-8 mr-3 -ml-1 animate-spin w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
</template>

<script>
import ParticipantInfo from 'Views/EnrollmentPresentation/StaticScreens/participant_info.vue';
import ImageCaptureContent from 'Views/EnrollmentPresentation/TemplateInstances/participant_image_capture_content.vue';
import routingService from "@/services/routingService";
import GenesysChat from "@/views/Shared/GenesysChat.vue";
import {useEnrollmentStore} from "@/stores/enrollment";
import {mapState, mapActions} from "pinia";

export default {
  name: 'ImageCapture',

  async mounted() {

    await this.loadEnrollees(this.$route.params.enrolleeId).then(() => {
      this.enrollmentId = this.getCurrentEnrollee.enrollmentId;
      this.enrolleeType = this.getCurrentEnrollee.type;
      this.enrolleeId = this.getCurrentEnrollee.id;
      this.showSpinner = false
    })
  },

  components: {
    GenesysChat,
    ParticipantInfo,
    ImageCaptureContent,
  },

  data() {
    return {
      participant: {
        firstName: '',
        lastName: '',
      },
      slideName: 'Image Capture',
      enrollmentId: '',
      enrolleeId: '',
      showSpinner: true,
      enrolleeType: '',
      packets: ''
    };
  },

  methods: {
    proceedToNext() {
      if (this.showSpinner) return;
      routingService.executeRoute(this.$router, 'Summary', this.enrollmentId, this.enrolleeId, this.enrolleeType);
    },
    returnToPrevious() {
      if (this.showSpinner) return;
      routingService.executeRoute(this.$router, this.enrolleeType == 2 ? 'IDCapture' : routingService.determineEsignRoute(this.packets, 8), this.enrollmentId, this.enrolleeId, this.enrolleeType);
    },
    ...mapActions(useEnrollmentStore, ['loadEnrollees']),
  },
  watch: {
    getFullPacket(newPacket) {
      this.packets = newPacket;
    }
  },
  computed: {
    ...mapState(useEnrollmentStore, ['getEnrollees', 'getCurrentEnrollee', 'getFullPacket']),
  },
};
</script>

<style></style>
