.
<template>
  <form id="caregiverinfo5" @submit.prevent="save()">
    <div class="px-4 lg:col-span-12 pt-0">
      <div class="grid grid-cols-6 gap-4">
        <div class="col-span-6 md:col-span-2">
          <label for="relationship_type_id" class="block text-gray-700">{{ $t('common.form.related', { name: caregiverinfo5.employer?.fullName || 'the employer of record' }) }}</label>
          <div class="flex items-center mt-1">
            <select
              @change="changeRelationships($event)"
              v-model="caregiverinfo5.relatedToParticipant"
              name="relationship_type_selection"
              class="chevron-down min-w-full mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-800 h-8"
              id="relationship_type_id"
            >
              <option selected disabled value="null">{{ $t('common.form.relationship.select') }}</option>
              <option :value="choice.value" v-for="choice in choices" :key="choice.value">
                {{ choice.name }}
              </option>
            </select>
            <span class="w-14 -ml-8">
              <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
    <ContinueBackButtons @back="back"></ContinueBackButtons>
  </form>
</template>

<script>
import {mask} from 'vue-the-mask';
import {getFormattedDate} from 'Utilities/helper';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';

export default {
  directives: { mask },
  props: ['caregiverData'],
  components: { ContinueBackButtons },
  emits: ['save', 'back'],
  data() {
    return {
      caregiverinfo5: {
        step: 5,
        gtEmployeeId: '',
        gtAssignmentId: '',
        startDate: '',
        relatedToParticipant: '',
        caregiverRelationshipTypesId: '',
        caregiverRelationshipTypesDesc: '',
        participantRelationshipDesc: '',
        employer: {},
      },
      choices: [
        { name: 'yes', value: true },
        { name: 'no', value: false },
      ],
    };
  },

  watch: {
    caregiverData(newEnrollees) {
      if (newEnrollees) {
        this.caregiverinfo5 = {
          step: 5,
          gtEmployeeId: newEnrollees.gtEmployeeId,
          gtAssignmentId: newEnrollees.gtAssignmentId,
          startDate: newEnrollees.startDate,
          relatedToParticipant: newEnrollees.relatedToParticipant,
          caregiverRelationshipTypesId: newEnrollees.caregiverRelationshipTypesId,
          caregiverRelationshipTypesDesc: newEnrollees.caregiverRelationshipTypesDesc,
          participantRelationshipDesc: newEnrollees.participantRelationshipDesc,
          employer: newEnrollees.employer,
        };
      }
    },
  },

  methods: {
    getFormattedDate,
    save() {
      this.$emit('save', this.caregiverinfo5);
    },

    back() {
      this.$emit('back');
    },
    changeRelationships(event){
      if(event.target.value == 'false'){
        this.caregiverinfo5.caregiverRelationshipTypesId = null;
        this.caregiverinfo5.caregiverRelationshipTypesDesc = null;
      }
      if(event.target.value == 'true'){
        this.caregiverinfo5.participantRelationshipDesc = null;
      }
    }
  },

  async created() {
    this.enrolleeId = this.$route.params.enrolleeId;
  },
};
</script>

<style></style>
