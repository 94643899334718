<template>
  <div class="w-full min-h-screen overflow-x-hidden overflow-y-auto bg-white" :class="showSpinner ? 'opacity-25' : ''">
    <div class="flex flex-1 h-screen">
      <main class="flex-1 h-full min-w-0 px-4 pt-4 overflow-x-hidden overflow-y-auto sm:pt-12 2xl:pt-2 lg:px-16 2xl:px-60 lg:flex"><div class="hidden lg:block w-96"></div>
        <section aria-labelledby="primary-heading" class="flex flex-col flex-1 h-full min-w-0 overflow-x-visible pt-14 lg:pt-0 lg:order-last">
          <ParticipantInfo>
            <template v-slot:header>
              <h1 class="px-4 mt-6 mb-10 text-3xl text-darkBlue lg:mt-16">
                <span v-if="document?.docFile?.prompts?.length === 0">{{ $t('doc.form.none') }}</span>
                <span v-if="document?.docFile?.prompts?.length > 0">{{ $t('doc.form.fill') }}</span>
              </h1>
            </template>
            <template v-slot:content>
              <DocumentCustomFields @save="proceedToNext" @back="returnToPrevious" />
            </template>
          </ParticipantInfo>
        </section>
      </main>
      <AppSideNavMenu :participantDetails="participant" :slide="slideName" :id="enrollmentId"/>
    </div>
  </div>
  <GenesysChat></GenesysChat>

  <!-- Spinner -->
  <div class="fixed flex justify-center my-3 text-gray-500 left-1/2 top-1/2" v-if="showSpinner">
    <svg class="h-8 mr-3 -ml-1 animate-spin w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
</template>

<script>
import ParticipantInfo from 'Views/EnrollmentPresentation/StaticScreens/participant_info.vue';
import DocumentCustomFields from 'Views/EnrollmentPresentation/TemplateInstances/document_customFields_content.vue';
import documentService from 'Services/backend/documents';
import { getEnrolleeInitials } from 'Utilities/helper';
import routingService from "@/services/routingService";
import GenesysChat from "@/views/Shared/GenesysChat.vue";
import {mapState, mapActions} from "pinia";
import {useEnrollmentStore} from "@/stores/enrollment";

export default {
  name: 'StateFormsCustomFields',
  components: {
    GenesysChat,
    ParticipantInfo,
    DocumentCustomFields,
  },
  mounted() {
    this.enrollmentId = this.$route.params.enrollmentId;
    this.enrolleeId = this.$route.params.enrolleeId;
    this.enrolleeType = this.$route.params.enrolleeType;
    this.docId = parseInt(this.$route.params.documentId);
    if (localStorage.getItem('visitedDocumentIdArray') != null) {
      this.visitedDocumentIdArray = JSON.parse(localStorage.getItem('visitedDocumentIdArray'));
    }
    if (!this.visitedDocumentIdArray.includes(this.docId)) {
      this.visitedDocumentIdArray.push(this.docId);
    }
    localStorage.setItem('visitedDocumentIdArray', JSON.stringify(this.visitedDocumentIdArray));
    this.getDocumentInformation();
  },

  data() {
    return {
      participant: {
        firstName: '',
        lastName: '',
      },
      enrollmentId: '',
      enrolleeId: '',
      slideName: 'State Documents',
      showSpinner: true,
      packets: {},
      enrolleeType: '',
      docId: '',
      visitedDocumentIdArray: [],
      filteredDocumentArray: [],
      nextDocumentId: '',
      document: {},
    };
  },

  methods: {
    getEnrolleeInitials,
    proceedToNext() {
      if (this.showSpinner) return;
      // const arr = JSON.parse(localStorage.getItem('visitedDocumentIdArray'));
      const routeToDocumentId = routingService.getDocumentRouteId(this.packets,'stateDocuments', this.docId, 1);
      routingService.executeRoute(this.$router, routeToDocumentId ? 'StateAcknowledgment' : 'E-Sign5', this.enrollmentId, this.enrolleeId, this.enrolleeType, routeToDocumentId || 0);
    },
    returnToPrevious() {
      if (this.showSpinner) return;
      routingService.executeRoute(this.$router, 'StateAcknowledgment', this.enrollmentId, this.enrolleeId, this.enrolleeType, this.docId);
    },
    ...mapActions(useEnrollmentStore, ['loadEnrollees']),

    getDocumentInformation() {
      documentService.getDocumentById(this.docId, this.enrolleeId).then(
        (response) => {
          this.document = response.data;
          if (!this.document.docFile.prompts.length) this.executeRoute('StateAcknowledgment', this.docId)
        },
        (error) => {
          console.error(error);
        },
      );
    }
  },

  computed: {
    ...mapState(useEnrollmentStore, ['getEnrollees', 'getFullPacket']),
  },

  watch: {
    getEnrollees(newEnrollees) {
      if (newEnrollees) {
        this.showSpinner = false;
        this.participant = getEnrolleeInitials(newEnrollees,this.enrolleeType) 
      }
    },
    getFullPacket(newPacket) {
      this.packets = newPacket;
    },
  },

  async created() {
    this.enrolleeId = this.$route.params.enrolleeId;
    await this.loadEnrollees(this.$route.params.enrolleeId);
  },
};
</script>

<style></style>
