.
<template>
  <form id="representativeInfo" class="px-4" @submit.prevent="save()">
    <div class="w-full justify-center gap-4 grid grid-cols-1">
      <div class="h-auto sm:h-auto sm:w-auto w-60 border-2 surrounding-shadow sm:py-3 sm:px-8 py-2 px-6 rounded-lg cursor-pointer border-gtGray200">
        <div @click="setRepSelection(true)" class="grid grid-cols-12 my-2">
          <input
              type="radio"
              :checked="hasRepresentative"
              class="focus:ring-gtBlue400 h-3 w-3 sm:h-4 sm:w-4 text-gtBlue100 border-gtBlue1300 col-span-1 cursor-pointer rep-radio-button"
          />
          <p class="sm:leading-5 text-gtGray900 col-span-11 ml-4 md:ml-0">{{ $t('rep.confirm') }}</p>
          <p class="sm:leading-5 text-gtGray500 col-start-2 col-span-11 ml-4 md:ml-0">{{ $t('rep.confirm.description') }}</p>
        </div>
        <div @click="setRepSelection(false)" class="grid grid-cols-12 my-1">
          <input
              type="radio"
              :checked="!hasRepresentative"
              class="focus:ring-gtBlue400 h-3 w-3 sm:h-4 sm:w-4 text-gtBlue100 border-gtBlue1300 col-span-1 cursor-pointer rep-radio-button"
          />
          <p class="sm:leading-5 text-gtGray900 col-span-11 ml-4 md:ml-0">{{ $t('rep.reject') }}</p>
          <p class="sm:leading-5 text-gtGray500 col-start-2 col-span-11 ml-4 md:ml-0">{{ $t('rep.reject.description') }}</p>
        </div>
      </div>
    </div>

    <div v-show="hasRepresentative" class="lg:col-span-12 mt-6 2xl:mt-4 pt-0">
      <div class="grid grid-cols-6 gap-4">
        <div class="col-span-6 sm:col-span-3">
          <label for="first_name" class="block text-gray-700">{{ $t('common.form.firstName') }}</label>
          <div class="flex items-center mt-1">
            <input
              v-model="repInfo.firstName"
              required
              oninvalid="this.setCustomValidity('Please enter First Name.')"
              oninput="this.setCustomValidity('')"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="Enter first name here"
              id="first_name"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label for="last_name" class="block text-gray-700">{{ $t('common.form.lastName') }}</label>
          <div class="flex items-center mt-1">
            <input
              v-model="repInfo.lastName"
              required
              oninvalid="this.setCustomValidity('Please enter Last Name.')"
              oninput="this.setCustomValidity('')"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="Enter last name here"
              id="last_name"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label for="alt_name" class="block text-gray-700">{{ $t('common.form.alias') }}</label>
          <div class="flex items-center mt-1">
            <input
              v-model="repInfo.altNameContact"
              required
              oninvalid="this.setCustomValidity('Enter previous/maiden name here.')"
              oninput="this.setCustomValidity('')"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="Enter previous/maiden name here"
              id="alt_name"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label for="primary_phone" class="block text-gray-700">{{ $t('common.form.primaryPhone') }}</label>
          <div class="mt-1 relative">
            <input
              v-model="repInfo.phonePrimary"
              @input="formatPrimaryNumber"
              required
              oninvalid="this.setCustomValidity('Please enter Phone Number.')"
              oninput="this.setCustomValidity('')"
              type="tel"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              id="primary_phone"
              pattern="\(\d\d\d\) \d\d\d-\d\d\d\d"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label for="email" class="block text-gray-700">{{ $t('common.form.email') }}</label>
          <div class="mt-1 relative">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              v-model="repInfo.email"
              type="email"
              required
              oninvalid="this.setCustomValidity('Please provide a valid email address.')"
              oninput="this.setCustomValidity('')"
              class="pl-10 text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="email@mail.com"
              id="email"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label for="date_of_birth" class="block text-gray-700">{{ $t('common.form.dateOfBirth') }}</label>
          <div class="flex items-center mt-1">
            <input
                v-model="repInfo.dateOfBirth"
                type="date"
                name="bday"
                :max="getCurrentDate()"
                class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                placeholder="mm/dd/yyyy"
                id="date_of_birth"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label for="social_security_number" class="block text-gray-700">{{ $t('common.form.socialSecurity') }}</label>
          <div class="mt-1 relative">
            <input
                v-model="repInfo.socialSecurityNumber"
                @input="formatSSN"
                pattern="\d\d\d-\d\d-\d\d\d\d"
                type="text"
                class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                placeholder="000-00-0000"
                id="social_security_number"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3">
          <label for="driver_license_number" class="block text-gray-700">{{ $t('common.form.driverLicense') }}</label>
          <div class="mt-1 relative">
            <input
                v-model="repInfo.driverLicenseId"
                type="text"
                class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                placeholder="000-000-0000"
                id="driver_license_number"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label for="participant_relation" class="block text-gray-700">
            {{ $t('common.form.relationship') }}
            <Popover content="For guardian, conservator, Parents (of a minor child) and POA relationships, you will need to provide GT with documentation of your status."></Popover>
          </label>
          <div class="flex items-center mt-1">
            <select
              v-model="relationship"
              required
              oninvalid="this.setCustomValidity('Please select relationship.')"
              oninput="this.setCustomValidity('')"
              id="participant_relation"
              name="participant_relation"
              autocomplete="participant_relation"
              class="chevron-down min-w-full mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none text-gray-800 h-8"
            >
              <option selected disabled value="">{{ $t('common.form.relationship.select') }}</option>
              <option :value="relation.id" v-for="relation in relations" :key="relation.id">
                {{ relation.name }}
              </option>
            </select>
            <span class="w-14 -ml-8">
              <svg class="h-4 w-6 text-gtGray500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          </div>
        </div>
        <div v-if="repInfo.relationship === 8" class="col-span-6 sm:col-span-6">
          <label for="other_relationship" class="block text-gray-700">{{ $t('common.form.ifOther') }}</label>
          <div class="mt-1 relative">
            <input
                required
                oninvalid="this.setCustomValidity('Please enter relationship description.')"
                oninput="this.setCustomValidity('')"
                v-model="repInfo.relationshipDescription"
                type="text"
                class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
                placeholder="Enter description"
                id="other_relationship"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-6">
          <label for="primary_street_address" class="block text-gray-700">{{ $t('common.form.primaryAddress') }}</label>
          <div class="mt-1 relative">
            <input
              required
              oninvalid="this.setCustomValidity('Please enter a valid address.')"
              oninput="this.setCustomValidity('')"
              v-model="repInfo.primaryAddress"
              ref="searchPrimary"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="Enter address"
              id="primary_street_address"
            />
          </div>
        </div>
      </div>
    </div>
    <ContinueBackButtons :submit="hasRepresentative" @continue="!hasRepresentative && save()" @back="back"></ContinueBackButtons> 
  </form>
</template>

<script>
import {EnvelopeIcon} from '@heroicons/vue/20/solid';
import {getFormattedDate, maskPhoneNumber} from 'Utilities/helper';
import {mask} from 'vue-the-mask';
import addressUtility from 'Utilities/addressUtility';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';
import Popover from "@/views/Shared/Popover.vue";
import {getCurrentDate, maskedSSN} from "@/utilities/helper";

export default {
  directives: { mask },
  emits: ['save', 'back'],
  props: ['participantData', 'relationshipData'],
  components: { 
    EnvelopeIcon,
    ContinueBackButtons,
    Popover
  },
  data() {
    return {
      representativeRelationship: false,
      relations: [],
      repInfo: {
        relationship: "0",
        firstName: '',
        lastName: '',
        altNameContact: '',
        phonePrimary: '',
        email: '',
        addressPrimary: {},
        relationshipDescription: '',
        socialSecurityNumber: '',
        dateOfBirth: '',
        driverLicenseId: '',
      },
      enrolleeType: '',
    };
  },
  computed: {
    hasRepresentative() {
        return Boolean(this.representativeRelationship)
    },
    relationship: {
        get: function () {
            return this.repInfo.relationship || ''
        },
        set: function (newRelationship) {
            this.repInfo.relationship = newRelationship
        }
    }
  },
  methods: {
    getCurrentDate,
    maskPhoneNumber,
    maskedSSN,
    getFormattedDate,
    setRepSelection(value) {
      this.representativeRelationship = value
    },
    formatPrimaryNumber() {
      this.repInfo.phonePrimary = maskPhoneNumber(this.repInfo.phonePrimary);
    },
    formatSSN() {
      this.repInfo.socialSecurityNumber = maskedSSN(this.repInfo.socialSecurityNumber);
    },
    save() {
      if (this.representativeRelationship) {
        this.$emit('save', this.repInfo);
      } else {
        this.repInfo?.relationship && this.$emit('delete')
        this.$router.push({ name: 'E-Sign1', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
      }
    },
    back() {
      this.$emit('back');
    },
    initPrimaryLocationSearch() {
      const autocomplete = new window.google.maps.places.Autocomplete(this.$refs.searchPrimary, {
        componentRestrictions: { country: ['us'] },
        fields: ['address_components'],
        types: ['address'],
      });

      autocomplete.addListener('place_changed', () => {
        this.repInfo.addressPrimary = addressUtility.parseAddressFromGoogleObject(autocomplete.getPlace(), autocomplete.gm_accessors_?.place?.Zr?.formattedPrediction);
      });
    },
  },
  watch: {
    participantData(newEnrollees) {
      if (newEnrollees) {
        this.repInfo = {
          relationship: newEnrollees?.relationship || 0,
          relationshipDescription: newEnrollees.relationshipDescription,
          phonePrimary: newEnrollees.phonePrimary,
          email: newEnrollees.email,
          dateOfBirth: newEnrollees.dateOfBirth,
          socialSecurityNumber: newEnrollees.socialSecurityNumber,
          driverLicenseId: newEnrollees.driverLicenseId,
          primaryAddress: newEnrollees.primaryAddress,
          firstName: newEnrollees.firstName,
          lastName: newEnrollees.lastName,
          altNameContact: newEnrollees.altNameContact,
          addressPrimary: {
            addressLine1: newEnrollees.addressPrimary.addressLine1,
            addressLine2: newEnrollees.addressPrimary.addressLine2,
            city: newEnrollees.addressPrimary.city,
            zipcode: newEnrollees.addressPrimary.zipcode,
            county: newEnrollees.addressPrimary.county,
            state: newEnrollees.addressPrimary.state,
          },
        };
        this.repInfo.phonePrimary = maskPhoneNumber(this.repInfo.phonePrimary);

        this.representativeRelationship = Boolean(this.repInfo?.relationship) || false
      }
    },
    relationshipData(participantRelationship) {
      if (participantRelationship) {
        this.relations = participantRelationship;
      }
    },
  },

  async mounted() {
    this.enrollmentId = this.$route.params.enrollmentId;
    this.enrolleeId = this.$route.params.enrolleeId;
    this.enrolleeType = this.$route.params.enrolleeType;
    window.checkAndAttachMapScript(this.initPrimaryLocationSearch);
  },
};
</script>

<style>
.rep-radio-button {
  margin: 3px 3px 3px 1rem;
}
</style>
