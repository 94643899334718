.
<template>
  <div class="w-full min-h-screen bg-white md:overflow-x-visible" :class="showSpinner ? 'opacity-25' : ''">
    <div class="flex flex-1 h-screen">
      <main class="flex-1 h-full min-w-0 px-4 pt-4 overflow-x-hidden overflow-y-auto sm:pt-12 2xl:pt-2 lg:px-16 2xl:px-60 lg:flex"><div class="hidden lg:block w-96"></div>
        <section aria-labelledby="primary-heading" class="flex flex-col flex-1 h-full min-w-0 overflow-x-visible pt-14 lg:pt-0 lg:order-last">
          <TransitionScreen>
            <template v-slot:header>
              <h1 class="px-4 mt-6 text-3xl text-darkBlue lg:mt-16">
                {{ $t('final.header', { firstName: participant.firstName }) }}
              </h1>
            </template>

            <template v-slot:description>
              <h3 class="px-4 mt-4 mb-10 text-base font-normal text-darkBlue md:text-xl">
                <span v-if="enrolleeType == 1 && hideButtonsForEnrollee">
                  {{ $t('final.enrolleeType1.description1') }}
                </span>
                <span v-if="enrolleeType == 1 && !hideButtonsForEnrollee">
                  {{ $t('final.enrolleeType1.description2') }}
                </span>
                <span v-if="enrolleeType == 2">
                  {{ $t('final.enrolleeType2.description1', { firstName: participantGivenCare.firstName }) }}
                </span>
                <div class="mt-8 mb-10">
                  <span class="text-2xl font-bold"></span>
                  <div class="mt-4 mb-7">{{ $t(`final.cta.description.enrolleeType${enrolleeType}`) }}</div>
                  <a class="rounded-3xl ease-out duration-100 bg-buttonBlue hover:bg-darkBlue text-trueWhite px-12 py-2 ml-auto" :href="this.portalLink" target="_blank">{{ $t('common.button.createAccount') }}</a>
                </div>
              </h3>
            </template>

            <template v-slot:content>
              <FinalSteps class="px-4" />
            </template>

            <template v-slot:callout> </template>

            <template v-slot:footer v-if="!hideButtonsForEnrollee">
              <ContinueBackButtons :continueContent="`${isEnrollmentComplete ? 'End' : 'Next'} Enrollment`" :continueClass="'outline-button'" @continue="proceedToNext" @back="returnToPrevious"></ContinueBackButtons>
            </template>
          </TransitionScreen>
        </section>
      </main>
      <AppSideNavMenu :participantDetails="participant" :slide="slideName" :id="enrollmentId"/>
    </div>
  </div>
  <GenesysChat></GenesysChat>

  <!-- Spinner -->
  <div class="fixed flex justify-center my-3 text-gray-500 left-1/2 top-1/2" v-if="showSpinner">
    <svg class="h-8 mr-3 -ml-1 animate-spin w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
</template>

<script>
import TransitionScreen from 'Views/EnrollmentPresentation/StaticScreens/transition.vue';
import FinalSteps from 'Views/EnrollmentPresentation/TemplateInstances/final_steps_content.vue';
import enrollmentService from 'Services/backend/enrollments';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';
import {checkEnrollmentProgress} from "@/utilities/helper";
import {ENV} from "@/config";
import * as moment from 'moment';
import GenesysChat from "@/views/Shared/GenesysChat.vue";
import {mapState, mapStores, mapActions} from "pinia";
import {useUserStore} from "@/stores/user";
import {useEnrollmentStore} from "@/stores/enrollment";

export default {
  name: 'Final-Steps',
  mounted() {
    this.enrollmentId = this.$route.params.enrollmentId;
    this.enrolleeId = this.$route.params.enrolleeId;
    this.enrolleeType = this.$route.params.enrolleeType;
    if(this.userStore.getLoggedInThroughJwtToken) {
      this.hideButtonsForEnrollee = this.userStore.getEnrolleeRoleId() == 5;
    }
    enrollmentService.updateEnrollmentProgress({ enrolleeId: this.enrolleeId, key: 'esignComplete'})
  },

  components: {
    GenesysChat,
    TransitionScreen,
    FinalSteps,
    ContinueBackButtons,
  },
  data() {
    return {
      participant: {
        firstName: '',
        lastName: '',
        dob: '',
        ssn: '',
        dtId: '',
      },
      participantGivenCare: {
        firstName: '',
        lastName: '',
      },
      slideName: 'Final Steps',
      enrollmentId: '',
      showSpinner: true,
      enrolleeType: '',
      hideButtonsForEnrollee: false,
      isEnrollmentComplete: false,
      portalLink: ''
    };
  },

  methods: {
    proceedToNext() {
      if (this.showSpinner) return;
      this.$router.push({ name: 'ParticipantLandingSlide', params: { enrollmentId: this.enrollmentId } });
    },
    returnToPrevious() {
      if (this.showSpinner) return;
       this.$router.go(-1);
    },
    getEnrolleeParams(type) {
      let extraIdentifiers = ''
      const originalSsn = localStorage.getItem('ssn')
      const originalDob = localStorage.getItem('dob')
      if (originalSsn !== this.participant.ssn || !moment(originalDob).isSame(moment(this.participant.dob))) {
        extraIdentifiers = `|${originalSsn}|${originalDob}`
      }
      switch(type) {
        case '1': return `participant?data=${encodeURIComponent(btoa(`${this.participant.gtId}${extraIdentifiers}|${this.participant.ssn}|${this.participant.dob}`))}`;
        case '2': return `caregiver?data=${encodeURIComponent(btoa(`${this.participant.gtId}${extraIdentifiers}|${this.participant.ssn}|${this.participant.dob}`))}`;
        case '3': return `vendor?data=${encodeURIComponent(btoa(`${this.participant.gtId}${extraIdentifiers}|${this.participant.ssn}|${this.participant.dob}`))}`;
        default: return ''
      }
    },
    ...mapActions(useEnrollmentStore, ['loadEnrollees']),
  },
  computed: {
    ...mapState(useEnrollmentStore, ['getEnrollees']),
    ...mapStores(useUserStore)
  },
  watch: {
    getEnrollees(newEnrollees) {
      if (newEnrollees) {
        this.showSpinner = false;
        this.isEnrollmentComplete = this.hideButtonsForEnrollee ? true : checkEnrollmentProgress(newEnrollees, this.enrolleeType);
        if (this.enrolleeType == 1) {
          this.participant = {
            firstName: newEnrollees?.participant?.firstName,
            lastName: newEnrollees?.participant?.lastName,
            dob: newEnrollees?.participant?.dateOfBirth,
            ssn: newEnrollees?.participant?.socialSecurityNumber.slice(-4, newEnrollees?.participant?.socialSecurityNumber.length),
            gtId: newEnrollees?.caseManager?.gtParticipantId
          };
        } else if (this.enrolleeType == 2) {
          this.participant = {
            firstName: newEnrollees?.caregiver?.firstName,
            lastName: newEnrollees?.caregiver?.lastName,
            dob: newEnrollees?.caregiver?.dateOfBirth,
            ssn: newEnrollees?.caregiver?.socialSecurityNumber.slice(-4, newEnrollees?.caregiver?.socialSecurityNumber.length),
            gtId: newEnrollees?.caregiver?.caregiverDetails?.gtAssignmentId
          };
          this.participantGivenCare.firstName = newEnrollees?.caregiver?.participants?.[0]?.firstName || newEnrollees?.caregiver?.participant?.firstName;
          this.participantGivenCare.lastName = newEnrollees?.caregiver?.participants?.[0]?.lastName || newEnrollees?.caregiver?.participant?.lastName;
        }
        this.portalLink = `https://${ENV === 'production' ? '' : 'dev-'}portal.gtindependence.com/account/register/${this.getEnrolleeParams(this.enrolleeType)}`
      }
    },
  },
  async created() {
    await this.loadEnrollees(this.$route.params.enrolleeId);
  },
};
</script>

<style></style>
