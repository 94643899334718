<template>
  <div class="text-darkBlue">
    <label v-if="(!files.length && !multiple) || multiple" aria-dropeffect="copy" class="relative flex items-center justify-center w-full p-4 mb-10 border-2 border-solid rounded-full cursor-pointer lg:p-8 lg:border-dashed border-buttonBlue lg:rounded-3xl shadow-full">
      <input ref="fileInput" type="file" class="absolute top-0 left-0 block w-full h-full opacity-0 cursor-pointer" :multiple="multiple && 'multiple'" :required="required" accept="image/jpeg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" @change="handleFileUpload($event)" />
      <svg class="w-8 h-8 mr-4 cursor-pointer md:w-12 md:h-12 text-buttonBlue" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.375 34.9062C8.0755 34.9062 2.96875 29.7995 2.96875 23.5C2.96875 17.9144 6.98357 13.2666 12.2848 12.2848C13.2666 6.98357 17.9144 2.96875 23.5 2.96875C29.0856 2.96875 33.7334 6.98357 34.7152 12.2848C40.0164 13.2666 44.0312 17.9144 44.0312 23.5C44.0312 29.7995 38.9245 34.9062 32.625 34.9062M16.6562 23.5L23.5 16.6562M23.5 16.6562L30.3438 23.5M23.5 16.6562V44.0312" stroke="currentColor" stroke-width="4.5625" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <div class="hidden lg:grid">
        <p class="text-lg font-bold text-buttonBlue">{{ $t('upload.instructions1') }}</p>
        <p class="text-sm">{{ $t('upload.instructions2') }}</p>
      </div>
      <p class="text-base font-bold text-buttonBlue lg:hidden">{{ $t('upload.instructions3') }}</p>
    </label>
    <ul class="flex flex-col gap-y-6">
      <li v-for="(file, index) of files" :key="index">
        <div class="flex items-center w-full px-6 py-4 bg-white border-2 border-solid rounded-full border-paleBlue gap-x-4">
          <svg v-if="!file.error" class="w-10 h-10 mx-2 min-w-min text-buttonBlue" viewBox="0 0 32.7 40.79" xmlns="http://www.w3.org/2000/svg">
            <path v-if="file.type.length > 3" d="M 20.54 0.003 H 3.989 c -1.779 0 -3.989 1.35 -3.989 3.45 v 33.88 a 3.8 3.8 0 0 0 3.4 3.45 h 25.26 a 3.781 3.781 0 0 0 4.04 -3.77 V 12.29 Z m 8.06 36.79 H 4.04 V 4.04 H 18.419 v 10.19 H 28.6 Z M 4.04 19.866 h 20.96 a 2 2 0 0 1 2 2 v 9 a 2 2 0 0 1 -2 2 h -21"  fill="currentColor"/>
            <path v-else d="M 20.54 0.003 H 3.989 c -1.779 0 -3.989 1.35 -3.989 3.45 v 33.88 a 3.8 3.8 0 0 0 3.4 3.45 h 25.26 a 3.781 3.781 0 0 0 4.04 -3.77 V 12.29 Z m 8.06 36.79 H 4.04 V 4.04 H 18.419 v 10.19 H 28.6 Z M 4.04 19.866 h 18 a 2 2 0 0 1 2 2 v 9 a 2 2 0 0 1 -2 2 h -18"  fill="currentColor"/>
            <text transform="translate(4.04 29.5)" fill="#fff" font-size="7.5" font-family="Lato-Medium, Lato" font-weight="500">
              <tspan> {{ file.type }} </tspan>
            </text>
          </svg>
          <svg v-else aria-hidden="true" class="w-10 h-10 mx-2 text-gtRed500 min-w-min" viewBox="0 0 20 20">
            <circle cx="10" cy="10" r="9" stroke-width="2" stroke="currentColor" fill="none" />
            <text transform="translate(7.1 -4)" fill="currentColor" font-size="16" font-family="Lato" font-weight="500">
                <tspan aria-hidden="true" x="0" y="20" style="speak: none" font-weight="800"> ! </tspan>
            </text>
          </svg>
          
          <div class="flex flex-col shrink w-full overflow-hidden">
            <div class="flex">
              <span :title="file.name" class="overflow-x-hidden whitespace-nowrap text-ellipsis"> {{ file.name }} </span>
              <span v-if="file.error" :title="file.error" class="grow text-gtRed500 min-w-max"> {{ file.error }} </span>
              <span v-if="file.progress < 100 && !file.error" class="ml-auto mr-2 text-xs text-buttonBlue"> {{ file.progress }}% </span>
            </div>
            <progress v-if="file.progress < 100 && !file.error" :value="file.progress" max="100" class="w-full h-2 rounded-lg bg-paleBlue"></progress>
            <p v-else-if="file.size != undefined" :class="file.error ? 'text-gtRed500':'text-buttonBlue'" class="text-xs"> {{ byteToReadable(file.size) }} </p>
          </div>

          <div class="flex items-end justify-end w-10 ml-auto gap-x-2 min-w-min">
            <span v-if="file.progress >= 100 && !file.error" class="font-extrabold"> &check;</span>
            <button type="button" @click="removeFile(file)"> 
              <svg aria-hidden="true" :class="file.error ? 'text-gtRed500':'text-buttonBlue'" class="w-6 h-6" viewBox="-2 -2 28 28" xmlns="http://www.w3.org/2000/svg" fill="none" stroke-width="3" stroke="currentColor" >
                <path stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </li>
    </ul>

    <div v-if="showRemoveDocumentModal">
      <RemoveDocumentModal
        :id="toDelete.id"
        :type="toDelete.type"
        @removePacket="removeDocument"
        @dismissPacketAgencyModal="dismissModal"
        modalTitle="Remove Document!"
        modalText="Are you sure you want to remove the document?"
      />
    </div>  
  </div>
  
</template>

<script>
import enrollmentService from 'Services/backend/enrollments';
import axios from 'axios'
import RemoveDocumentModal from 'Views/Packets/SharedPacketComponents/Modals/RemoveConfirmationModal';

export default {
  name: 'DocumentUpload',
  components: { RemoveDocumentModal },
  props: {
    enrolleeId: {},
    required: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    forcedName: {
      // Used to identify specific docs like passport for just a front-end change.
      type: String,
      default: null
    },
    alreadySent: {
      type: Array, // [ { name, size } ]
      default: new Array(0)
    }
  },
  data() {
    return {
      showRemoveDocumentModal: false,
      files: [],
      toDelete: {}
    };
  },
  watch: {
    alreadySent() { this.$forceUpdate() }
  },
  mounted() {
    for (let file of this.alreadySent) {
      if (file)
        this.files.push({
          symbol: Symbol(), 
          name: file.name,
          id: file.id,
          type: file.name.match(/[^.]+$/g)[0].toLocaleUpperCase() || '',
          progress: 100,
          cancel() {}
        });
    }
  },
  methods: {
    handleFileUpload(event) {
      for (let rawFile of event.target.files) {
        const symbol = Symbol();
        const cancelSource = axios.CancelToken.source();
        
        let name = rawFile.name;
        if (this.forcedName) {
          let ext = rawFile.name.match(/\.[^.]*$/gi)[0] || '';
          name = this.forcedName + ext;
        }

        this.files.push({
          symbol, 
          name,
          size: rawFile.size,
          progress: 0,
          cancel: cancelSource.cancel
        });

        const file = this.files.find(file => file.symbol === symbol)

        switch (rawFile.type) {
          case 'image/jpeg': 
            file.type = 'JPG'; break;
          case 'image/png': 
            file.type = 'PNG'; break;
          case 'application/pdf': 
            file.type = 'PDF'; break;
          case 'application/msword': 
            file.type = 'DOC'; break;
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 
            file.type = 'DOCX'; break;
          default: 
            file.error = 'Please upload a jpg/png/pdf/doc/docx file only';
            file.type = '? ? ?';
            continue;
        }

        if (rawFile.size > 8*1048576) {
          file.error = 'Please upload a file under 8 MB'
          continue;
        } 

        // Must do single file uploads for upload progress
        const formData = new FormData();
        formData.append('actualFiles', rawFile, name);
        formData.append('entityType', 4);
        formData.append('enrolleeId', this.enrolleeId);
        formData.append('disk', 'private');
        enrollmentService.uploadDocumentsForEnrollment(formData, cancelSource.token, (event) => {
          file.progress = Math.floor((event.loaded / event.total) * 99); // 99 to account for waiting for response
        }).then(res => {
          file.progress = 100;

          if (res.isAxiosError) file.error = res.message;
          else file.id = res.data[0].id
        });
      }
    },
    removeFileFromList(symbol) {
      this.files = this.files.filter(f => f.symbol !== symbol);
    },
    removeFile(file) {
      if (file.progress >= 100 && !file.error) {
        if (file.id) {
          this.showRemoveDocumentModal = true;
          this.toDelete = file;
        }
      } else {
        file.cancel();
        this.removeFileFromList(file.symbol);
      }
    },
    removeDocument() {
      enrollmentService.removeEnrolleeDocuments(this.toDelete.id, {enrolleeId: this.enrolleeId}).then(res => {
          if (res.isAxiosError) console.error(res);
          else this.removeFileFromList(this.toDelete.symbol);
        },
      );
    },
    byteToReadable(bytes) {
      if (bytes >= 1048576)   { bytes = (bytes / 1048576).toFixed() + " mb"; }
      else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed() + " kb"; }
      else                    { bytes = bytes + " bytes"; }

      return bytes;
    },
    dismissModal() {
      this.showRemoveDocumentModal = false;
    }
  }
}
</script>

<style lang="postcss" scoped>
label:has(input[type="file"]:focus-visible) {
  outline: 1px solid black;
}

progress::-webkit-progress-bar {
  @apply bg-paleBlue rounded-lg;
}

progress::-webkit-progress-value {
  @apply bg-buttonBlue rounded-lg;
}

progress::-moz-progress-bar {
  @apply bg-buttonBlue rounded-lg;
}


</style>
