.
<template>
  <form id="participantInfo2" @submit.prevent="save()">
    <div class="px-4 lg:col-span-12 pt-0">
      <div class="grid grid-cols-6 gap-4">
        <div class="col-span-6 sm:col-span-3">
          <label for="primary_phone" class="block text-gray-700">
            {{ $t('common.form.primaryPhone') }}
            <span class="text-gtRed500">*</span>
          </label>
          <div class="mt-1 relative">
            <input
              v-model="participantInfo2.phonePrimary"
              @input="acceptNumber"
              required
              oninvalid="this.setCustomValidity('Please match the requested format.')"
              oninput="this.setCustomValidity('')"
              type="tel"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              id="primary_phone"
              pattern="\(\d\d\d\) \d\d\d-\d\d\d\d"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <div class="flex justify-between">
            <label for="secondary_phone" class="block text-gray-700">{{ $t('common.form.secondaryPhone') }}</label>
          </div>
          <div class="mt-1 relative">
            <input
              v-model="participantInfo2.phoneSecondary"
              oninvalid="this.setCustomValidity('Please match the requested format.')"
              oninput="this.setCustomValidity('')"
              type="tel"
              @input="acceptSecondaryNumber"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              id="secondary_phone"
              pattern="\(\d\d\d\) \d\d\d-\d\d\d\d"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-6">
          <label for="email" class="block text-gray-700">
            {{ $t('common.form.email') }}
          </label>
          <div class="mt-1 relative">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              v-model="participantInfo2.email"
              type="email"
              oninvalid="this.setCustomValidity('Please provide a valid email address.')"
              oninput="this.setCustomValidity('')"
              class="pl-10 text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="email@mail.com"
              id="email"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-6">
          <label for="email" class="block text-gray-700">
            {{ $t('common.form.signingEmail') }}
            <span class="text-gtRed500">*</span>
          </label>
          <div class="mt-1 relative">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              v-model="participantInfo2.signingEmail"
              type="email"
              required
              oninvalid="this.setCustomValidity('Please provide a valid email address.')"
              oninput="this.setCustomValidity('')"
              class="pl-10 text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="email@mail.com"
              id="signingEmail"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-6">
          <label for="primary_street_address" class="block text-gray-700">
            {{ $t('common.form.homeAddress') }}
            <span class="text-gtRed500">*</span>
          </label>
          <div class="mt-1 relative">
            <input
              ref="searchPrimary"
              v-model="primaryAddress1"
              required
              oninvalid="this.setCustomValidity('Please verify your home address.')"
              oninput="this.setCustomValidity('')"
              type="type"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="Enter address"
              id="primary_street_address"
              @keydown.enter.prevent
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-6">
          <div class="mt-1 relative flex items-center ">
            <input
              v-model="isMailingAddressSame"
              @change="checkSameMailingAddress()"
              type="checkbox"
              id="is_mailing_address_same"
              class="cursor-pointer"
            />
            <label for="is_mailing_address_same" class="block text-gray-700 ml-4 cursor-pointer">
              {{ $t('common.form.mailingAddressIsSame') }}
            </label>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-6">
          <div class="flex justify-between">
            <label for="alternate_street_address" class="block text-gray-700">
              {{ $t('common.form.mailingAddress') }}
            </label>
          </div>          
          <div class="mt-1 relative">
            <input
              ref="searchSecondary"
              v-model="secondaryAddress1"
              type="type"
              oninput="this.setCustomValidity('')"
              @input="secondaryAddressSuggested = false; isMailingAddressSame = false"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="Enter address"
              id="alternate_street_address"
              @keydown.enter.prevent
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label for="county" class="block text-gray-700">
            {{ $t('common.form.county') }}
            <span class="text-gtRed500">*</span>
          </label>
          <div class="flex items-center mt-1">
            <input
              v-model="participantInfo2.addressPrimary.county"
              required
              oninvalid="this.setCustomValidity('Please enter County.')"
              oninput="this.setCustomValidity('')"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              id="county"
              placeholder="County"
            />
          </div>
        </div>
        <span class="col-span-3 hidden sm:block"></span>
        <div class="col-span-6 sm:col-span-3">
          <label for="language" class="block text-gray-700">
            {{ $t('common.form.preferredLanguage') }}
          </label>
          <div class="flex items-center mt-1">
            <select
              v-model="participantInfo2.language"
              id="language"
              name="language"
              @change="changeLanguage()"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
            >
            <option :value="null" hidden></option>
              <option v-for="(option, key) in languages" :value="option.name" :key="key">
                {{ option.name }}
              </option>
              <option ref="other_language_option" :value="participantInfo2.language">Other</option>
            </select>
          </div>
        </div>
        <div v-if="isOtherLanguage" class="col-span-6 sm:col-span-3">
          <label for="language_other" class="block text-gray-700">
            {{ $t('common.form.preferredLanguage') }}
          </label>
          <div class="flex items-center mt-1">
            <input
              v-model="participantInfo2.language"
              id="language_other"
              name="language_other"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
            />
          </div>
        </div>
      </div>
    </div>
    <ContinueBackButtons @back="back"></ContinueBackButtons>
  </form>
</template>

<script>
import {EnvelopeIcon} from '@heroicons/vue/20/solid';
import {maskPhoneNumber} from 'Utilities/helper';
import {mask} from 'vue-the-mask';
import addressUtility from 'Utilities/addressUtility';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';
import languageService from 'Services/backend/languages';

export default {
  directives: { mask },
  components: {
    EnvelopeIcon,
    ContinueBackButtons,
  },

  props: ['participantData'],
  emits: ['save', 'back', 'loading'],
  data() {
    return {
      counties: '11',
      todayDate: '',
      languages: [],
      isOtherLanguage: false,
      secondaryAddressSuggested: true,
      secondaryAddress1: '',
      isMailingAddressSame: false,

      participantInfo2: {
        step: 2,
        phonePrimary: '',
        phoneSecondary: '',
        email: '',
        signingEmail: '',
        primaryAddress: '',
        alternateAddress: '',
        county: '',
        addressPrimary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          zipcode: '',
          county: '',
          state: '',
        },
        addressSecondary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          zipcode: '',
          county: '',
          state: '',
        },
        regionId: '1',
        language: null,
      },
    };
  },
  computed: {
    primaryAddress1() {
      const validAddr = this.participantInfo2?.addressPrimary;
      return addressUtility.addressToString(validAddr);
    },
  },
  methods: {
    maskPhoneNumber,

    changeLanguage() {
      this.isOtherLanguage = this.$refs['other_language_option']?.selected;
      if (this.isOtherLanguage) this.participantInfo2.language = '';
    },

    acceptNumber() {
      this.participantInfo2.phonePrimary = maskPhoneNumber(this.participantInfo2.phonePrimary);
    },

    acceptSecondaryNumber() {
      this.participantInfo2.phoneSecondary = maskPhoneNumber(this.participantInfo2.phoneSecondary);
    },

    async save() {
      this.$emit('loading', true);

      if (!this.secondaryAddress1.trim().length) this.participantInfo2.addressSecondary = {};
      else if (!this.secondaryAddressSuggested) this.participantInfo2.addressSecondary = await addressUtility.verifyAddress(this.secondaryAddress1);
      
      this.$emit('loading', false);

      if (!this.participantInfo2.addressSecondary) {
        this.$refs['searchSecondary'].setCustomValidity('Please verify your mailing address.');
        this.$refs['searchSecondary'].reportValidity();
      } else this.$emit('save', this.participantInfo2);
    },

    back() {
      this.$emit('back');
    },
    initPrimaryLocationSearch() {
      for (const ref in this.$refs) {
        if (ref === 'searchPrimary') {
          const rf = 'searchPrimary';
          const autocomplete = new window.google.maps.places.Autocomplete(this.$refs[rf], { componentRestrictions: { country: ['us'] }, fields: ['address_components'], types: ['address'] });
          autocomplete.addListener('place_changed', () => {
            this.participantInfo2.addressPrimary = addressUtility.parseAddressFromGoogleObject(autocomplete.getPlace(), autocomplete.gm_accessors_?.place?.Zr?.formattedPrediction);
            this.checkSameMailingAddress();
          });
        } else if (ref === 'searchSecondary') {
          const rf = 'searchSecondary';
          const autocomplete2 = new window.google.maps.places.Autocomplete(this.$refs[rf], {
            componentRestrictions: { country: ['us'] },
            fields: ['address_components'],
            types: ['address'],
          });
          autocomplete2.addListener('place_changed', () => {
            this.participantInfo2.addressSecondary = addressUtility.parseAddressFromGoogleObject(autocomplete2.getPlace(), autocomplete2.gm_accessors_?.place?.jj?.formattedPrediction);
            this.secondaryAddress1 = this.$refs[rf].value; // Autocomplete updates the input, but not the vue-model
            this.secondaryAddressSuggested = true;
          });
        }
      }
    },
    async getLanguages() {
      await languageService.getLanguages().then(
        response => this.languages = response.data.languages,
        error => console.error(error),
      );
    },
    checkSameMailingAddress() {
      if (this.isMailingAddressSame) {
        this.secondaryAddress1 = this.primaryAddress1;
        this.participantInfo2.addressSecondary = this.participantInfo2.addressPrimary;
        this.secondaryAddressSuggested = true;
      }
    },
  },

  watch: {
    participantData(newEnrollees) {
      if (newEnrollees) {
        this.participantInfo2 = {
          step: 2,
          phonePrimary: newEnrollees.phonePrimary,
          phoneSecondary: newEnrollees.phoneSecondary,
          email: newEnrollees.email,
          signingEmail: newEnrollees.signingEmail,
          primaryAddress: newEnrollees.primaryAddress,
          alternateAddress: newEnrollees.alternateAddress,
          county: newEnrollees.county,
          regionId: '1',
          language: newEnrollees?.language,
          addressPrimary: {
            addressLine1: newEnrollees.addressPrimary.addressLine1,
            addressLine2: newEnrollees.addressPrimary.addressLine2,
            city: newEnrollees.addressPrimary.city,
            zipcode: newEnrollees.addressPrimary.zipcode,
            county: newEnrollees.addressPrimary.county,
            state: newEnrollees.addressPrimary.state,
          },
          addressSecondary: {
            addressLine1: newEnrollees.addressSecondary.addressLine1,
            addressLine2: newEnrollees.addressSecondary.addressLine2,
            city: newEnrollees.addressSecondary.city,
            zipcode: newEnrollees.addressSecondary.zipcode,
            county: newEnrollees.addressSecondary.county,
            state: newEnrollees.addressSecondary.state,
          },
        };
        this.participantInfo2.phonePrimary = maskPhoneNumber(this.participantInfo2.phonePrimary);
        this.participantInfo2.phoneSecondary = maskPhoneNumber(this.participantInfo2.phoneSecondary);
        
        this.isOtherLanguage = (this.participantInfo2.language && this.languages.filter(lang => lang.name === this.participantInfo2.language).length === 0);
        this.secondaryAddress1 = addressUtility.addressToString(this.participantInfo2.addressSecondary);
        this.isMailingAddressSame = JSON.stringify(this.participantInfo2.addressPrimary) == JSON.stringify(this.participantInfo2.addressSecondary);
      }
    },
  },
  async mounted() {
    window.checkAndAttachMapScript(this.initPrimaryLocationSearch);
    await this.getLanguages();
  },
};
</script>

<style></style>
