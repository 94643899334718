.
<template>
  <form id="vendorInfo1" @submit.prevent="save()">
    <div class="px-4 lg:col-span-12 pt-0">
      <div class="grid grid-cols-6 gap-4">
        <div class="col-span-6 sm:col-span-3">
          <label for="last_name" class="block text-gray-700">{{ $t('common.form.vendorName') }} <span class="text-gtRed500">*</span></label>
          <div class="flex items-center mt-1">
            <input
              v-model="vendorInfo1.lastName"
              required
              oninvalid="this.setCustomValidity('Please enter First Name.')"
              oninput="this.setCustomValidity('')"
              type="text"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="Enter vendor name here"
              id="last_name"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label for="primary_phone" class="block text-gray-700">{{ $t('common.form.primaryPhone') }} <span class="text-gtRed500">*</span></label>
          <div class="flex items-center mt-1">
            <input
              v-model="vendorInfo1.phonePrimary"
              required
              oninvalid="this.setCustomValidity('Please enter Phone Number.')"
              oninput="this.setCustomValidity('')"
              @input="acceptNumber"
              type="tel"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              id="primary_phone"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <div class="flex justify-between">
            <label for="secondary_phone" class="block text-gray-700">{{ $t('common.form.secondaryPhone') }}</label>
          </div>
          <div class="flex items-center mt-1">
            <input
              v-model="vendorInfo1.phoneSecondary"
              type="tel"
              @input="acceptSecondaryNumber"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              id="secondary_phone"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3"></div>
        <div class="col-span-6 sm:col-span-6">
          <label for="email" class="block text-gray-700">{{ $t('common.form.email') }} <span class="text-gtRed500">*</span></label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <EnvelopeIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              v-model="vendorInfo1.email"
              type="email"
              required
              oninvalid="this.setCustomValidity('Please enter email')"
              oninput="this.setCustomValidity('')"
              class="pl-10 text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="email@mail.com"
              id="email"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label for="social_security" class="block text-gray-700">{{ $t('common.form.socialSecurity') }} <span class="text-gtRed500">*</span></label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input
              @input="formatSSN()"
              v-model="vendorInfo1.socialSecurityNumber"
              type="text"
              required
              oninvalid="this.setCustomValidity('Please enter ssn')"
              oninput="this.setCustomValidity('')"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="000-000-0000"
              id="social_security"
            />
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <label for="email" class="block text-gray-700">{{ $t('common.form.ein') }} <span class="text-gtRed500">*</span></label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input
              v-model="vendorInfo1.employerIdentificationNumber"
              type="text"
              required
              oninvalid="this.setCustomValidity('Please enter employer identification number')"
              oninput="this.setCustomValidity('')"
              class="text-gray-800 h-8 mb-1 block w-full bg-white rounded-md surrounding-shadow py-1 px-3 focus:outline-none"
              placeholder="000-000-0000"
            />
          </div>
        </div>
      </div>
    </div>
    <ContinueBackButtons @back="back"></ContinueBackButtons>
  </form>
</template>

<script>
import {EnvelopeIcon} from '@heroicons/vue/20/solid';
import {maskedSSN, maskPhoneNumber} from 'Utilities/helper';
import ContinueBackButtons from 'Views/Shared/ContinueBackButtons.vue';

export default {
  emits: ['save', 'back'],
  components: {
    EnvelopeIcon,
    ContinueBackButtons,
  },

  props: ['participantData'],

  data() {
    return {
      agencies: [],
      vendorInfo1: {
        step: 1,
        firstName: '',
        lastName: '',
        phonePrimary: '',
        phoneSecondary: '',
        email: '',
        socialSecurityNumber: '',
        employerIdentificationNumber: '',
      },
    };
  },

  methods: {
    maskPhoneNumber,
    maskedSSN,

    acceptNumber() {
      this.vendorInfo1.phonePrimary = maskPhoneNumber(this.vendorInfo1.phonePrimary);
    },

    acceptSecondaryNumber() {
      this.vendorInfo1.phoneSecondary = maskPhoneNumber(this.vendorInfo1.phoneSecondary);
    },
    formatSSN() {
      this.vendorInfo1.socialSecurityNumber = maskedSSN(this.vendorInfo1.socialSecurityNumber);
    },

    save() {
      this.$emit('save', this.vendorInfo1);
    },

    back() {
      this.$emit('back');
    },
  },

  watch: {
    participantData(newEnrollees) {
      if (newEnrollees) {
        this.vendorInfo1 = {
          step: 1,
          firstName: newEnrollees.firstName,
          lastName: newEnrollees.lastName,
          phonePrimary: newEnrollees.phonePrimary,
          phoneSecondary: newEnrollees.phoneSecondary,
          email: newEnrollees.email,
          socialSecurityNumber: newEnrollees.socialSecurityNumber,
          employerIdentificationNumber: newEnrollees.employerIdentificationNumber,
        };
        this.vendorInfo1.phonePrimary = maskPhoneNumber(this.vendorInfo1.phonePrimary);
        this.vendorInfo1.phoneSecondary = maskPhoneNumber(this.vendorInfo1.phoneSecondary);
        this.formatSSN();
      }
    },
  },

  async created() {
    this.enrolleeId = this.$route.params.enrolleeId;
  },
};
</script>

<style></style>
