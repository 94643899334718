.
<template>
  <div class="w-full min-h-screen overflow-x-hidden overflow-y-auto bg-white" :class="showSpinner ? 'opacity-25' : ''">
    <div class="flex flex-1 h-screen">
      <main class="flex-1 h-full min-w-0 px-4 pt-4 overflow-x-hidden overflow-y-auto sm:pt-12 2xl:pt-2 lg:px-16 2xl:px-60 lg:flex"><div class="hidden lg:block w-96"></div>
        <section aria-labelledby="primary-heading" class="flex flex-col flex-1 h-full min-w-0 overflow-x-visible pt-14 lg:pt-0 lg:order-last">
          <ParticipantInfo>
            <template v-slot:header>
              <h1 class="px-4 mt-6 text-3xl text-darkBlue lg:mt-16">
                {{ $t('eor.header') }}
              </h1>
            </template>

            <template v-slot:description>
              <h3 class="px-4 mt-4 text-base font-normal text-darkBlue md:text-xl">
                {{ $t('eor.description1') }}
              </h3>
              <h3 class="px-4 mt-4 text-base font-normal text-darkBlue md:text-xl">
                {{ $t('eor.description2') }}
              </h3>
              <h3 class="px-4 mt-4 mb-10 text-base font-normal text-darkBlue md:text-xl" v-html="$t('eor.cta')"></h3>
            </template>

            <template v-slot:content>
              <EORInfoContent :relationshipData="relationsData" :participantData="eorInfo" :eorIsParticipant="eorIsParticipant" @save="proceedToNext" @back="returnToPrevious" @checkForParticipant="checkIfParticipantSelected" />
            </template>
          </ParticipantInfo>
        </section>
      </main>
      <AppSideNavMenu :participantDetails="participantInfo" :slide="slideName" :id="enrollmentId"/>
    </div>
  </div>
  <GenesysChat></GenesysChat>

  <!-- Spinner -->
  <div class="fixed flex justify-center my-3 text-gray-500 left-1/2 top-1/2" v-if="showSpinner">
    <svg class="h-8 mr-3 -ml-1 animate-spin w-7 text-gtBlue600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
</template>

<script>
import ParticipantInfo from 'Views/EnrollmentPresentation/StaticScreens/participant_info.vue';
import EORInfoContent from 'Views/EnrollmentPresentation/TemplateInstances/eor_info_content.vue';
import {mapState, mapActions} from 'pinia';
import enrollmentService from 'Services/backend/enrollments';
import addressUtility from 'Utilities/addressUtility';
import edpService from '../../Enrollments/EnrollmentDetails/service';
import GenesysChat from "@/views/Shared/GenesysChat.vue";
import {useEnrollmentStore} from "@/stores/enrollment";

export default {
  name: 'EORInformation',
  mounted() {
    this.enrollmentId = this.$route.params.enrollmentId;
    this.enrolleeId = this.$route.params.enrolleeId;
    this.enrolleeType = this.$route.params.enrolleeType;
  },
  components: {
    GenesysChat,
    ParticipantInfo,
    EORInfoContent,
  },
  data() {
    return {
      participantInfo: {
        firstName: '',
        lastName: '',
      },
      eorInfo: {
        phonePrimary: '',
        email: '',
        primaryAddress: '',
        dateOfBirth: '',
        socialSecurityNumber: '',
        ein: '',
        participantSSN: '',
        firstName: '',
        lastName: '',
        middleInitial: '',
        relationship: '',
        addressPrimary: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          zipcode: '',
          county: '',
          state: '',
        },
      },
      enrollmentId: '',
      slideName: 'Employer of Record Information',
      showSpinner: true,
      enrolleeType: '',
      relationsData: [],
    };
  },
  methods: {
    returnToPrevious() {
      if (this.showSpinner) return;
      this.$router.push({ name: 'ParticipantInformation3', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
    },
    proceedToNext(data) {
      if (this.showSpinner) return;
      const body = {
        type: 'EMPLOYER',
        employer: data,
      };

      enrollmentService.updateEnrolleeDetails(this.enrolleeId, body).then(
        (response) => {
          edpService.processEnrolleePresentationSavedResponse(response, this.$toast.bind(this));
          this.$router.push({ name: 'RepresentativeInformation', params: { enrollmentId: this.enrollmentId, enrolleeId: this.enrolleeId, enrolleeType: this.enrolleeType } });
        },
        (error) => {
          edpService.processEnrolleePresentationSavedResponse(error, this.$toast.bind(this));
          console.error(error);
        },
      );
    },
    checkIfParticipantSelected(isParticipantEOR) {
      if (isParticipantEOR == true) {
        this.eorInfo = {
          phonePrimary: this.getEnrollees?.participant?.phonePrimary,
          email: this.getEnrollees?.participant?.email,
          primaryAddress: addressUtility.addressToString(this.getEnrollees?.participant?.addressPrimary),
          dateOfBirth: this.getEnrollees?.participant?.dateOfBirth,
          socialSecurityNumber: this.getEnrollees?.participant?.socialSecurityNumber,
          ein: this.getEnrollees?.employers?.ein,
          firstName: this.getEnrollees?.participant?.firstName,
          lastName: this.getEnrollees?.participant?.lastName,
          middleInitial: this.getEnrollees?.participant?.middleInitial,
          relationship: 8,
          addressPrimary: {
            addressLine1: this.getEnrollees?.participant?.addressPrimary?.addressLine1,
            addressLine2: this.getEnrollees?.participant?.addressPrimary?.addressLine2,
            city: this.getEnrollees?.participant?.addressPrimary?.city,
            zipcode: this.getEnrollees?.participant?.addressPrimary?.zipcode,
            county: this.getEnrollees?.participant?.addressPrimary?.county,
            state: this.getEnrollees?.participant?.addressPrimary?.stateAbbrev,
          },
        };
      } else {
        this.eorInfo = {
          phonePrimary: this.getEnrollees?.employers?.phonePrimary,
          email: this.getEnrollees?.employers?.email,
          primaryAddress: addressUtility.addressToString(this.getEnrollees?.employers?.address),
          dateOfBirth: this.getEnrollees?.employers?.dateOfBirth,
          socialSecurityNumber: this.getEnrollees?.employers?.ssn,
          ein: this.getEnrollees?.employers?.ein,
          firstName: this.getEnrollees?.employers?.firstName,
          lastName: this.getEnrollees?.employers?.lastName,
          middleInitial: this.getEnrollees?.employers?.middleInitial,
          relationship: this.getEnrollees?.employers?.relationship,
          addressPrimary: {
            addressLine1: this.getEnrollees?.employers?.address?.addressLine1,
            addressLine2: this.getEnrollees?.employers?.address?.addressLine2,
            city: this.getEnrollees?.employers?.address?.city,
            zipcode: this.getEnrollees?.employers?.address?.zipcode,
            county: this.getEnrollees?.employers?.address?.county,
            state: this.getEnrollees?.employers?.address?.stateAbbrev,
          },
        };
      }
      this.eorInfo.participantSSN = this.getEnrollees?.participant?.socialSecurityNumber;
    },
    ...mapActions(useEnrollmentStore, ['loadEnrollees', 'loadRelations']),
  },
  computed: {
    ...mapState(useEnrollmentStore, ['getEnrollees', 'getRelationships']),
    eorIsParticipant: function () {
      return this.getEnrollees?.participant?.socialSecurityNumber === this.eorInfo?.socialSecurityNumber
          && this.getEnrollees?.participant?.firstName === this.eorInfo?.firstName
          && this.getEnrollees?.participant?.lastName === this.eorInfo?.lastName
          && this.eorInfo?.relationship === 8
    }
  },

  watch: {
    getEnrollees(newEnrollees) {
      if (newEnrollees) {
        this.showSpinner = false;
        const tempEnrollees = {
          phonePrimary: newEnrollees?.employers?.phonePrimary,
          email: newEnrollees?.employers?.email,
          primaryAddress: addressUtility.addressToString(newEnrollees?.employers?.address),
          dateOfBirth: newEnrollees?.employers?.dateOfBirth,
          socialSecurityNumber: newEnrollees?.employers?.ssn,
          participantSSN: newEnrollees?.participant?.socialSecurityNumber,
          ein: newEnrollees?.employers?.ein,
          firstName: newEnrollees?.employers?.firstName,
          lastName: newEnrollees?.employers?.lastName,
          middleInitial: newEnrollees?.employers?.middleInitial,
          relationship: newEnrollees?.employers?.relationship,
          addressPrimary: {
            addressLine1: newEnrollees?.employers?.address?.addressLine1,
            addressLine2: newEnrollees?.employers?.address?.addressLine2,
            city: newEnrollees?.employers?.address?.city,
            zipcode: newEnrollees?.employers?.address?.zipcode,
            county: newEnrollees?.employers?.address?.county,
            state: newEnrollees?.employers?.address?.stateAbbrev,
          },
        };

        const validAddr = newEnrollees?.employers?.address;
        tempEnrollees.primaryAddress = addressUtility.addressToString(validAddr);

        this.eorInfo = tempEnrollees;
        this.participantInfo.firstName = newEnrollees.participant.firstName;
        this.participantInfo.lastName = newEnrollees.participant.lastName;
        
        if (this.eorInfo.socialSecurityNumber == null || this.eorInfo.socialSecurityNumber?.length == 0) {
          this.checkIfParticipantSelected(true); // make checkbox checked by default
        }
      }
    },
    getRelationships(participantRelationship) {
      if (participantRelationship) {
        this.relationsData = participantRelationship;
      }
    },
  },

  async created() {
    this.enrolleeId = this.$route.params.enrolleeId;
    await this.loadEnrollees(this.$route.params.enrolleeId);
    await this.loadRelations();
  },
};
</script>

<style></style>
